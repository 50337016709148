import React from 'react'
import clsx from 'clsx'

import LayoutInnerPage from '@src/layouts/InnerPage'

import Seo from '@components/seo/Index'
import CompanyBanner from '@components/global/banner/Company'
import Navigation from '@components/pages/sitemap/Navigation'

import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'

import { pageData as page, bannerData, navData } from '@src/data/Sitemap'
import useCompanyMetadata from '@hooks/company-metadata'

const Sitemap = () => {
  const { SitemapBannerBg, SitemapNavBadge } = useCompanyMetadata()

  return (
    <LayoutInnerPage
      hasBlackTransparentNav
      hasFloatingSvgMono
      showFooterNav={false}
      showFooterBg={false}
    >
      <Seo title={page.title} uri={page.uri} />
      <CompanyBanner
        layout="default"
        bannerBg={extractImage(SitemapBannerBg)}
        breadcrumbs={page.breadcrumbs}
        title={bannerData.title.text}
        titleClassName={bannerData.title.className}
        subTitle={bannerData.subTitle.text}
        subTitleClassName={bannerData.subTitle.className}
      />
      <Navigation
        badgeImg={extractImage(SitemapNavBadge)}
        navItems={navData.navItems}
        titleClassName={navData.titleClassName}
        categoryClassName={navData.categoryClassName}
        itemClassName={navData.itemClassName}
      />
    </LayoutInnerPage>
  )
}

export default Sitemap
