import React from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from '@components/seo/Breadcrumbs'
import clsx from 'clsx'
import parse from 'html-react-parser'

import * as cx from './CompanyBanner.module.scss'

const CompanyBanner = ({
  layout = 'default',
  bannerBg,
  breadcrumbs,
  title,
  titleClassName = 'heading-3',
  subTitle,
  subTitleClassName = 'small-heading',
}) => {
  return (
    <section
      id="hero"
      className={clsx(cx.wrapper)}
      style={{
        backgroundImage: `url(${bannerBg ? bannerBg : ''})`,
      }}
    >
      <div className={clsx(cx.content)}>
        <Breadcrumbs
          wrapperClassName={clsx(cx.breadcrumbs, 'global-breadcrumbs')}
          data={breadcrumbs}
        />
        <div
          className={clsx(
            layout === 'default' && cx.defaultContent,
            layout === 'alt' && cx.altContent
          )}
        >
          {title && <h1 className={clsx(titleClassName)}>{parse(title)}</h1>}
          {subTitle && <p className={subTitleClassName}>{parse(subTitle)}</p>}
        </div>
      </div>
    </section>
  )
}

CompanyBanner.propTypes = {
  layout: PropTypes.oneOf(['default', 'alt']),
  bannerBg: PropTypes.string,
  breadcrumbs: PropTypes.array.isRequired,
  title: PropTypes.string,
  headingClassName: PropTypes.string,
  subTitle: PropTypes.string,
  subTitleClassName: PropTypes.string,
}

export default CompanyBanner
