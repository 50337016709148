import React, { useRef } from 'react'
import parse from 'html-react-parser'
import ElementImage from '@components/global/element/Image'

import { useNavigationMetadata } from '@hooks/navigation-metadata'
import { flatListToHierarchical } from '@src/utils/flatListToHierarchical'

import * as cx from './Navigation.module.scss'

const NavItem = ({
  item,
  badgeImg,
  titleClassName,
  categoryClassName,
  itemClassName,
}) => {
  return (
    <li>
      <div className={cx.navTitleContainer}>
        <div className={cx.titleBadge}>
          <ElementImage src={badgeImg} alt={'Badge'} />
        </div>
        <div className={cx.titleText}>
          <h1 className={titleClassName}>{parse(item.title)}</h1>
        </div>
      </div>
      <div className={item.items.length <= 2 ? cx.navListFlex : cx.navListGrid}>
        {item.items.map((item, index) => {
          return (
            <div key={index} className={cx.itemContainer}>
              <div className={cx.categoryTitle}>
                <a className={categoryClassName}>{item.title}</a>
              </div>
              <div className={cx.navList}>
                {item.children.map((navItem, key) => {
                  return (
                    <a key={key} className={itemClassName}>
                      {navItem.title}
                    </a>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
      <hr />
    </li>
  )
}

const Navigation = ({
  badgeImg,
  navItems,
  titleClassName,
  categoryClassName,
  itemClassName,
}) => {
  let navList = navItems
  if (navList[0].items.length === 0) {
    // no nav items from query/graphQL

    const hierarchicalList = flatListToHierarchical(
      useNavigationMetadata().footerMenu.menuItems.nodes
    )

    hierarchicalList.forEach((item, index) => {
      if (index <= 3) {
        // 0-3 items, navItem 0
        navList[0].items.push(item)
      } else if (index <= 5) {
        // 4-5, navItem 1
        navList[1].items.push(item)
      } else if (index <= 7) {
        // 6-7, navItem 2
        navList[2].items.push(item)
      } else {
        // 8-9, navItem 3
        navList[3].items.push(item)
      }
    })
  }

  // console.log(navList)

  return (
    <section id="navigation" className={cx.wrapper}>
      <div className={`${cx.container} container`}>
        <ul>
          {navList.map((item, key) => {
            return (
              <NavItem
                key={key}
                item={item}
                titleClassName={titleClassName}
                categoryClassName={categoryClassName}
                itemClassName={itemClassName}
                badgeImg={badgeImg}
              />
            )
          })}
        </ul>
      </div>
    </section>
  )
}

export default Navigation
