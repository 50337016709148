export const pageData = {
  title: 'Sitemap',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    { text: 'Sitemap', url: '/sitemap/' },
  ],
  uri: '/sitemap',
  buttonsHeight: { desktop: 1880, tablet: 2200, phone: 2880 },
  lcf: {
    titleClassName: 'heading-2',
  },
}

export const bannerData = {
  title: {
    text: 'Agent Image Sitemap',
    className: 'heading-3',
  },
  subTitle: {
    text:
      '<strong>An overview of where everything is!</strong> Navigate your way to find what you need.',
    className: 'small-heading',
  },
}

export const navData = {
  navItems: [
    {
      title: 'The Best Websites In Real Estate.',
      items: [
        {
          title: 'Our Work',
          url: '/our-work/',
          children: [
            { title: 'Our Portfolio', url: '/sample-page/' },
            { title: 'Award-Winning Websites', url: '/sample-page/' },
            { title: 'Case Studies', url: '/sample-page/' },
            { title: 'Success Stories', url: '/sample-page/' },
            {
              title: 'Before-and-After Website Transformations',
              url: '/sample-page/',
            },
          ],
        },
        {
          title: 'Real Estate Websites',
          url: '/real-estate-websites/',
          children: [
            { title: 'Agent Image X', url: '/sample-page/' },
            { title: 'Agent Pro', url: '/sample-page/' },
            { title: 'Semi-Custom', url: '/sample-page/' },
            { title: 'Imagine Studio', url: '/sample-page/' },
          ],
        },
        {
          title: 'Choose Website by Expertise',
          url: '/sample-page/',
          children: [
            { title: 'New Agent', url: '/sample-page/' },
            { title: 'Sellers Agent', url: '/sample-page/' },
            { title: 'Buyers Agent', url: '/sample-page/' },
            { title: 'Industry Leader', url: '/sample-page/' },
            { title: 'Luxery Specialist', url: '/sample-page/' },
          ],
        },
        {
          title: 'Choose Website by Scale',
          url: '/sample-page/',
          children: [
            { title: 'Individual Agent', url: '/sample-page/' },
            { title: 'Small/Team Boutique', url: '/sample-page/' },
            { title: 'Large Team', url: '/sample-page/' },
            { title: 'Brokerage', url: '/sample-page/' },
            { title: 'Enterprise', url: '/sample-page/' },
          ],
        },
      ],
    },
    {
      title: 'Digital Marketing To<br/> Sharpen Your Online Edge.',
      items: [
        {
          title: 'Real Estate Digital Marketing',
          url: '/digital-marketing/',
          children: [
            { title: 'SEO Solutions', url: '/sample-page/' },
            { title: 'Social Media Solutions', url: '/sample-page/' },
            { title: 'PPC Solutions', url: '/sample-page/' },
            { title: 'Blogging Solutions', url: '/sample-page/' },
            { title: 'Custom Marketing Solutions', url: '/sample-page/' },
          ],
        },
        {
          title: 'Choose By Marketing Goal',
          url: '/thank-you/',
          children: [
            { title: 'Lead Generation', url: '/sample-page/' },
            { title: 'Search Engine Visibility', url: '/sample-page/' },
            { title: 'Website Traffic', url: '/sample-page/' },
            { title: 'Local Marketing', url: '/sample-page/' },
            { title: 'Social Growth', url: '/sample-page/' },
            { title: 'Brand Awareness', url: '/sample-page/' },
          ],
        },
      ],
    },
    {
      title: 'Resources For Real Estate Agents.',
      items: [
        {
          title: 'Resources',
          url: '/resources/',
          children: [
            { title: 'IDX Solutions', url: '/sample-page/' },
            { title: 'Best-in-Class Partners', url: '/sample-page/' },
            { title: 'Events', url: '/sample-page/' },
            { title: 'Customer Support', url: '/sample-page/' },
          ],
        },
        {
          title: 'Agent Image Blog',
          url: '/sample-page/',
          children: [
            { title: 'Lead Generation', url: '/sample-page/' },
            { title: 'Search Engine Visibility', url: '/sample-page/' },
            { title: 'Website Traffic', url: '/sample-page/' },
            { title: 'Local Marketing', url: '/sample-page/' },
            { title: 'Social Growth', url: '/sample-page/' },
            { title: 'Brand Awareness', url: '/sample-page/' },
          ],
        },
      ],
    },
    {
      title: 'Learn More About Agent Image.',
      items: [
        {
          title: 'Company',
          url: '/sample-page/',
          children: [
            { title: 'About Us', url: '/sample-page/' },
            { title: 'Why Agent Image?', url: '/sample-page/' },
            { title: 'How We Work With You', url: '/sample-page/' },
            { title: 'Our Clients', url: '/sample-page/' },
            { title: 'Contact Us', url: '/sample-page/' },
          ],
        },
        {
          title: 'Legal',
          url: '/sample-page/',
          children: [
            { title: 'Terms of Service', url: '/sample-page/' },
            { title: 'Privacy Policy', url: '/sample-page/' },
            { title: 'ADA Compliance', url: '/sample-page/' },
            { title: 'CCPA', url: '/sample-page/' },
          ],
        },
      ],
    },
  ],
  titleClassName: 'subtitle-9',
  categoryClassName: 'small-heading strong',
  itemClassName: 'default-body',
}
