import { useStaticQuery, graphql } from 'gatsby'

const useCompanyMetadata = () => {
  const query = useStaticQuery(graphql`
    query companyMetadata {
      TermsBannerBg: file(relativePath: { eq: "terms-privacy/banner-bg.jpg" }) {
        id
        ...CompanyImageOptimize
      }
      SitemapBannerBg: file(relativePath: { eq: "sitemap/banner-bg.jpg" }) {
        id
        ...CompanyImageOptimize
      }
      SitemapNavBadge: file(relativePath: { eq: "sitemap/badge.png" }) {
        id
        ...CompanyImageOptimize
      }
    }

    fragment CompanyImageOptimize on File {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 90
          formats: [AUTO, WEBP]
        )
      }
    }
  `)

  return query
}

export default useCompanyMetadata
