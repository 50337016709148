// extracted by mini-css-extract-plugin
export var categoryTitle = "Navigation-module--category-title--5bf3c";
export var container = "Navigation-module--container--f9ba2";
export var itemContainer = "Navigation-module--item-container--4825f";
export var navList = "Navigation-module--nav-list--e6c5d";
export var navListFlex = "Navigation-module--nav-list-flex--bca55";
export var navListGrid = "Navigation-module--nav-list-grid--20466";
export var navTitleContainer = "Navigation-module--nav-title-container--64880";
export var titleBadge = "Navigation-module--title-badge--ee780";
export var titleText = "Navigation-module--title-text--9dcef";
export var wrapper = "Navigation-module--wrapper--cfba6";